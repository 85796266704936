import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { areCheckoutCreditsUsed, areCheckoutVouchersUsed, fetchUser, getPaises, onlyUpdateCredits, onlyUpdateVouchers, verifyOrder } from "../../actions/miniCartActions";
import { getUserCheckoutDefaultValues } from "../../actions/userActions";
import { funnelStep } from "../../Analytics/DataLayer";
import Toast from "../../helpers/Toast";
import usePrevious from "../../helpers/usePrevious";
import CartCheckout from "./CartCheckout";
import "./Checkout.css";
import CodigosPromocionais from "./CodigosPromocionais";
import Creditos from "./Creditos";
import DadosEnvio from "./DadosEnvio/DadosEnvio";
import DadosFaturacao from "./DadosFaturacao/DadosFaturacao";
import FormasEnvio from "./FormasEnvio";
import LoginCheckout from "./Login/LoginCheckout";
import Observacao from "./Observacao";
import TiposPagamento from "./TiposPagamento";
import Total from "./Total";
import Vales from "./Vales";
import GuestCheckout from "./Guest/GuestCheckout";

export default function Checkout() {

	const dispatch = useDispatch();

	const user = useSelector((state: any) => state.user);
	const currencies = useSelector((state: any) => state.currencies);
	const oldCurrencies: any = usePrevious(currencies);

	const shouldVerifyOrder = useSelector((state: any) => state.shouldVerifyOrder);
	const freeOrder: number = useSelector((state: any) => state.checkoutData.ProdutosTodosGratuitos);
	const selectedShippingTypeExternal = useSelector((state: any) => state.checkoutData.TipoTransporteExterno ?? "S");
	const numberOfPhysicalProducts: number = useSelector((state: any) => state.checkoutData.TemProdutosFisicos);
	const total = useSelector((state: any) => state.checkoutData.Total);
	const isGuestCheckout = useSelector((state: any) => state.isGuestCheckout);
	const isRegisteringUser = useSelector((state: any) => state.loginOrRegisterInCheckout?.toUpperCase() === "REGISTER")

	useEffect(() => {
		funnelStep(6);
		dispatch(fetchUser());
		dispatch(getUserCheckoutDefaultValues());

		//Moradas precisam dos países
		dispatch(getPaises());
		return () => { }
	}, [])

	useEffect(() => {
		if (shouldVerifyOrder === true) {
			loadOrder();
		}

	}, [shouldVerifyOrder])

	const loadOrder = async () => {
		try {
			var data: any = await dispatch(verifyOrder());
			if (typeof data.json.checkoutData.MensagemErro !== "undefined" && data.json.checkoutData.MensagemErro !== null && data.json.checkoutData.MensagemErro != "") {
				Toast.Show("error", data.json.checkoutData.MensagemErro);
			}
		} catch (exp) { }
	}

	const calculateCurrenciesChanges = async () => {

		//Ficamos como a nova moeda
		var selectedCurrencyObj = currencies.list.find(c => c.ID_Moeda == currencies.selected);

		//Ficamos com a antiga
		var lastSelectedCurrencyObj = currencies.list.find(c => c.ID_Moeda == oldCurrencies.selected);

		//Se tivermos encontrado as duas moedas
		if (selectedCurrencyObj != null && lastSelectedCurrencyObj != null) {

			//Ficamos com o valor de cambio das duas
			var currencyExchangeRate = selectedCurrencyObj.ValorConversao;
			var previousCurrencyExchangeRate = lastSelectedCurrencyObj.ValorConversao;

			//Verificamos se créditos e vouchers estão a ser usados
			var areCreditsUsedResult: any = dispatch(areCheckoutCreditsUsed());
			var areVouchersUsedResult: any = dispatch(areCheckoutVouchersUsed(

			));

			//Se os créditos estiverem a ser usados
			if (areCreditsUsedResult.areCreditsUsed === true) {

				//Convertemos os créditos da moeda antiga para a nova moeda
				var newCredits = areCreditsUsedResult.credits;

				//Para fazer o round é necessário multiplicar por 100 e depois dividir por 100
				newCredits = Math.round((newCredits * currencyExchangeRate / previousCurrencyExchangeRate) * 100) / 100;
				dispatch(onlyUpdateCredits(newCredits));
			}

			//Se os voucher estiverem a ser usados
			if (areVouchersUsedResult.areVouchersUsed === true) {

				//Convertemos os vouchers da moeda antiga para a nova moeda
				var newVouchers = areVouchersUsedResult.vouchers;

				//Para fazer o round é necessário multiplicar por 100 e depois dividir por 100
				newVouchers = Math.round((newVouchers * currencyExchangeRate / previousCurrencyExchangeRate) * 100) / 100;
				dispatch(onlyUpdateVouchers(newVouchers));
			}
		}

		var data: any = await dispatch(verifyOrder());
		var errorMessage = data?.json?.checkoutData?.MensagemErro;
		if (errorMessage !== undefined && errorMessage != null && errorMessage.trim().length > 0) {
			Toast.Show("error", errorMessage);
		}
	}

	useEffect(() => {
		calculateCurrenciesChanges();
	}, [currencies.selected])

	return (
		<div>
			<Row className="checkoutTitleParent">
				<Col>
					<div className="checkoutTitle">
						<FormattedMessage id="shop.checkout.title" />
					</div>
				</Col>
			</Row>
			<hr />

			{isGuestCheckout ?
				<GuestCheckout />
				:
				user.userID == null || typeof user.userID == 'undefined' ?
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							{/*LOGIN E REGISTO*/}
							<Row style={{ marginBottom: "15px" }}>
								<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }}>
									<LoginCheckout previousInCheckout={true} />
								</Col>
							</Row>
						</Col>
					</Row>
					:
					null
			}

			{(isGuestCheckout || user.userID || isRegisteringUser) &&
				<Row>
					{/*DADOS ESQUERDA*/}
					<Col xs={12} sm={12} md={12} lg={7}>
						{/*DADOS FATURAÇÃO*/}
						{freeOrder != 1 &&
							<Row style={{ marginBottom: "15px" }}>
								<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }}>
									<DadosFaturacao />
								</Col>
							</Row>
						}

						{/*FORMA DE ENVIO*/}
						{numberOfPhysicalProducts !== 0 &&
							<Row style={{ marginBottom: "15px" }}>
								<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }} className="formasEnvioCol">
									<FormasEnvio />
								</Col>
							</Row>
						}
						{/*DADOS ENVIO*/}
						{(selectedShippingTypeExternal === 'S' && numberOfPhysicalProducts !== 0) &&
							<Row style={{ marginBottom: "15px" }}>
								<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }}>
									<DadosEnvio />
								</Col>
							</Row>
						}

						{/*CÓDIGO PROMOCIONAL, VALES, CRÉDITOS*/}
						{freeOrder != 1 &&
							<Row>
								<Col xs={12} sm={12} md={12} lg={4}>
									<CodigosPromocionais />
								</Col>
								<Col xs={12} sm={12} md={12} lg={4}>
									<Vales />
								</Col>
								<Col xs={12} sm={12} md={12} lg={4}>
									<Creditos />
								</Col>
							</Row>
						}

						{/*OBSERVAÇÕES*/}
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px", minHeight: "100%" }} className="observacaoCol">
								<Observacao />
							</Col>
						</Row>
					</Col>

					{/*DADOS DIREITA*/}
					<Col xs={12} sm={12} md={12} lg={5}>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }} className="cartCol">
								<CartCheckout />
							</Col>
						</Row>

						{/*TIPO PAGAMENTO*/}
						{total != 0 &&
							<Row id="tiposPagamento">
								<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }} className="tiposPagamentoCol">
									<TiposPagamento />
								</Col>
							</Row>
						}

						{/*TOTAL*/}
						<Row >
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px", minHeight: "100%" }} className="totalCol">
								<Total user={user} showMoreDetailsOnMount={true} />
							</Col>
						</Row>
					</Col>
				</Row>
			}
		</div >
	);
}
import ActionPageview from "material-ui/svg-icons/action/pageview";
import {
  ISVERIFIEDRECAPTCHA,
  SIDEBARMENU,
  LOADING,
  LOADINGCART,
  FETCH_CART_NUMBER_ITEMS,
  CHECKOUT_PANEL_BILLING_ADDRESS,
  CHECKOUT_PANEL_SHIPPING_ADDRESS,
  CHECKOUT_PANEL_SHIPPING_METHOD,
  CHECKOUT_PANEL_PAYMENT_METHOD,
  LOGIN,
  GETUSER,
  FETCH_CART_BEGIN,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  INCREMENTAR_PRODUCT_CART,
  DECREMENTAR_PRODUCT_CART,
  FETCH_LANGUAGE,
  FETCH_CURRENCY,
  CHANGE_LANGUAGE,
  CHANGE_CURRENCY,
  FETCH_CATEGORIES,
  FETCH_PRODUCTS,
  MENU_TOGGLE,
  CART_TOGGLE,
  PROFILE_TOGGLE,
  FETCH_PRODUCTS_DETAILS,
  FETCH_PAYMENT_TYPES,
  FETCH_SHIPMENT_TYPES,
  FETCH_DELIVERY_ADDRESSES,
  FETCH_BILLING_ADDRESSES,
  FETCH_CHECKOUT_DATA,
  LOGOUT,
  REGISTER,
  FETCH_PRODUCTS_FILTERS,
  ALL_COUNTRIES,
  SELECT_PAYMENT_INSTALLMENTS,
  SELECT_PAYMENT_PHONE_NUMBER,
  LOADING_CHECKOUT_PRICES,
  LOADING_ALL_PAGE,
  ERROR_CODE_CHECKOUT,
  BILLING_ADDRESS,
  SHIPPING_ADDRESS,
  SHOULD_VERIFY_ORDER,
  USER_TO_REGISTER,
  LOGIN_OR_REGISTER_IN_CHECKOUT,
  IS_GUEST_CHECKOUT,
} from "../actions/miniCartActions";

import { USER_CHECKOUT_DEFAULT_VALUES } from "../actions/userActions";

import { SITE } from "../actions/geralActions";

const initialState = {
  user: {},
  cart: [],
  isVerified: false,
  sideBarMenu: false,
  loadingAllPage: false,
  loading: false,
  loadingCart: false,
  cartNumberItems: 0,
  cartLoadError: null,
  languages: [],
  currencies: [],
  categories: [],
  products: [],
  menuToggleState: false,
  cartToggleState: false,
  profileToggleState: false,
  product: {},
  paymentTypes: [],
  shipmentTypes: [],
  deliveryAddresses: [],
  billingAddresses: [],
  checkoutData: {
    shippingAddress: null,
    billingAddress: null,
    selectedShipmentType: null,
    selectedPaymentType: null,
    ID_Portes: null,
    observacoes: null,
    ChaveCodigoPromocional: null,
    CodigoVale: null,
    vouchers: null,
    credits: null,
  },
  selectedPaymentType: null,
  selectedPaymentInstallments: 1,
  selectedPaymentPhoneNumber: null,
  filters: {},
  panelBillingState: true,
  panelShippingAddressState: false,
  panelShippingMethodState: false,
  panelPaymentMethodState: false,
  campaign: 0,
  company: 0,
  newSubscription: 0,
  allCampaigns: [],
  allPaises: [],
  allLeadTypes: [],
  loadingCheckoutPrices: false,
  errorCode: "",
  billingAddress: {},
  shippingAddress: {},
  userCheckoutDefaultValues: {
    transportType: null,
    paymentType: null
  },
  shouldVerifyOrder: false,
  userToRegister: {},
  loginOrRegisterInCheckout: "login",
  isGuestCheckout: false,
  site: "ihtp"
};

const miniCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_GUEST_CHECKOUT:
      return {
        ...state,
        isGuestCheckout: action.payload.isGuestCheckout
      }

    case LOGIN_OR_REGISTER_IN_CHECKOUT:
      return {
        ...state,
        loginOrRegisterInCheckout: action.payload.loginOrRegisterInCheckout
      }

    case USER_TO_REGISTER:
      return {
        ...state,
        userToRegister: action.payload.userToRegister
      }

    case SHOULD_VERIFY_ORDER:
      return {
        ...state,
        shouldVerifyOrder: action.payload.shouldVerifyOrder
      }

    case USER_CHECKOUT_DEFAULT_VALUES:
      return {
        ...state,
        userCheckoutDefaultValues: action.payload.userCheckoutDefaultValues
      }

    case LOADING_ALL_PAGE:
      return {
        ...state,
        loadingAllPage: action.payload.loadingAllPage
      }

    case LOADING_CHECKOUT_PRICES:
      return {
        ...state,
        loadingCheckoutPrices: action.payload.loadingCheckoutPrices
      }

    case SELECT_PAYMENT_PHONE_NUMBER:
      return {
        ...state,
        selectedPaymentPhoneNumber: action.payload.selectedPaymentPhoneNumber
      }

    case SELECT_PAYMENT_INSTALLMENTS:
      return {
        ...state,
        selectedPaymentInstallments: action.payload.selectedPaymentInstallments
      }


    case ISVERIFIEDRECAPTCHA:
      return {
        ...state,
        isVerified: action.payload.isVerifiedState
      };
    case SIDEBARMENU:
      return {
        ...state,
        sideBarMenu: action.payload.sideBarMenuState
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload.loadingState
      };
    case LOADINGCART:
      return {
        ...state,
        loadingCart: action.payload.loadingCartState
      };
    case CHECKOUT_PANEL_BILLING_ADDRESS:
      return {
        ...state,
        panelBillingState: action.payload.panelBillingState
      };
    case FETCH_CART_NUMBER_ITEMS:
      return {
        ...state,
        cartNumberItems: action.payload.cartNumberItems
      };
    case CHECKOUT_PANEL_SHIPPING_ADDRESS:
      return {
        ...state,
        panelShippingAddressState: action.payload.panelShippingAddressState
      };
    case CHECKOUT_PANEL_SHIPPING_METHOD:
      return {
        ...state,
        panelShippingMethodState: action.payload.panelShippingMethodState
      };
    case CHECKOUT_PANEL_PAYMENT_METHOD:
      return {
        ...state,
        panelPaymentMethodState: action.payload.panelPaymentMethodState
      };

    case FETCH_PRODUCTS_DETAILS:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        product: action.payload.product
      };

    case LOGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        user: action.payload.user
      };

    case GETUSER:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        user: action.payload.user
      };

    case LOGOUT:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        user: []
      };

    case REGISTER:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state
      };

    case ALL_COUNTRIES: {
      return {
        ...state,
        allPaises: action.payload.allPaises
      };
    }

    case FETCH_CART_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        cartLoadError: null
      };

    case FETCH_CART_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        cart: action.payload.cartSuccess
      };

    case FETCH_CART_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        cartLoadError: action.payload.cartLoadError,
      };

    case INCREMENTAR_PRODUCT_CART:
      return {
        ...state,
        cart: action.payload.cart
      };

    case DECREMENTAR_PRODUCT_CART:
      return {
        ...state,
        cart: action.payload.cart
      };

    // components/header/language/language.js
    case FETCH_LANGUAGE:
      return {
        ...state,
        languages: action.payload.language
      };

    // components/header/currency/currency.js
    case FETCH_CURRENCY:
      return {
        ...state,
        currencies: action.payload.currency
      };

    // components/header/language/language.js
    case CHANGE_LANGUAGE:
      return {
        ...state,
        languages: action.payload.language
      };

    case CHANGE_CURRENCY:
      return {
        ...state,
        currencies: action.payload.currency
      };

    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };

    case MENU_TOGGLE:
      return {
        ...state,
        menuToggleState: action.payload.menuToggleState
      };

    case CART_TOGGLE:
      return {
        ...state,
        cartToggleState: action.payload.cartToggleState
      };

    case PROFILE_TOGGLE:
      return {
        ...state,
        profileToggleState: action.payload.profileToggleState
      };

    case FETCH_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: action.payload.paymentTypes
      };

    case FETCH_SHIPMENT_TYPES:
      return {
        ...state,
        shipmentTypes: action.payload.shipmentTypes
      };

    case FETCH_DELIVERY_ADDRESSES:
      return {
        ...state,
        deliveryAddresses: action.payload.deliveryAddresses
      };

    case FETCH_BILLING_ADDRESSES:
      return {
        ...state,
        billingAddresses: action.payload.billingAddresses
      };

    case FETCH_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: action.payload.checkoutData
      };

    case FETCH_PRODUCTS_FILTERS:
      return {
        ...state,
        filters: action.payload.filters
      };

    case ERROR_CODE_CHECKOUT:
      return {
        ...state,
        errorCode: action.payload.errorCode
      };

    case BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload.billingAddress
      };

    case SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload.shippingAddress
      };

    case SITE:
      return {
        ...state,
        site: action.payload.site
      }

    default:
      return state;
  }
};

export default miniCartReducer;

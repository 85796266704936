import { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { SiteFactoryClass } from "../../../../classes/SiteFactory";
import SiteLinksHelper from "../../../../helpers/SiteLinksHelper";

const siteInstance = SiteFactoryClass.GetInstance();

class CartIsEmpty extends Component {
    render() {
        const { site } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        <h3><FormattedMessage id="shop.cart.carrinho.carrinhoVazio" /></h3>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <a href={SiteLinksHelper.links.storeUrl}>
                            <Button
                                aria-label="Increment"
                                style={{
                                    backgroundColor: siteInstance.site?.btnBgColour,
                                    border: "none",
                                    minWidth: isMobile ? "50%" : "20%"
                                }}>
                                <FormattedMessage id="shop.cart.carrinho.irParaLoja" />
                            </Button>
                        </a>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        site: state.site
    };
};

export default connect(mapStateToProps)(injectIntl(CartIsEmpty));
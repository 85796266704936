import IhtpSite from '../../../classes/IhtpSite'
import { SiteFactoryClass } from '../../../classes/SiteFactory'
import WonderlivesSite from '../../../classes/WonderlivesSite'
import RegisterBenefitsIhtp from './Components/RegisterBenefitsIhtp'
import RegisterBenefitsTitle from './Components/RegisterBenefitsTitle'
import RegisterBenefitsWonderlives from './Components/RegisterBenefitsWonderlives'

const siteInstance = SiteFactoryClass.GetInstance();

export default function RegisterBenefits() {
    return (
        <>
            <RegisterBenefitsTitle />

            {siteInstance.site === IhtpSite &&
                <RegisterBenefitsIhtp />
            }

            {siteInstance.site === WonderlivesSite &&
                <RegisterBenefitsWonderlives />
            }
        </>
    )
}
interface TawkToInfoProps {
    language: string,
    key: string,
}

export default class TawkToInfo {
    info: TawkToInfoProps[] = []
    propertyId = "";

    constructor(propertyId: string) {
        this.propertyId = propertyId;
    }

    public Add(language: string, widgetId: string) {
        const info: TawkToInfoProps = {
            language: language,
            key: widgetId
        }

        this.info.push(info);
    }
}
export interface RegisterBenefitsLabelProps {
    text: string,
    className: string,
    icon?: JSX.Element
}

export default function RegisterBenefitsLabel({ text, className, icon }: RegisterBenefitsLabelProps) {
    return (
        <label className={className}>{icon} {text}</label>
    )
}
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ToastContainer } from "react-toastr"
import { Col, Row } from 'reactstrap'
import { requestNewEmailLink, verifyEmailCode } from '../../actions/miniCartActions'
import { SiteFactoryClass } from '../../classes/SiteFactory'
import Routes from '../../helpers/Routes'
import { Sleep } from '../../Utils/UsefulFunctions'
import IHTPSpacer from '../Common/IHTPSpacer'

export interface VerifyEmailRouteState {
    email?: string,
    previousInCheckout: boolean,
    shouldLoginAfterConfirm: boolean
}


let container;

const siteInstance = SiteFactoryClass.GetInstance();

export default function VerifyEmail() {

    const { state: routeState } = useLocation<VerifyEmailRouteState>();
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingEmail, setLoadingEmail] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const [loadingNewCode, setLoadingNewCode] = useState<boolean>(false);
    const user = useSelector((state: any) => state.user);

    const verifyCodeClick = async () => {

        if (recaptchaVerified === false) return;

        if (typeof code === "undefined" || code.trim().length <= 0) {
            setError(intl.formatMessage({ id: "confirmarEmail.codeEmpty" }));
            return;
        }

        setError("");
        setLoading(true);
        const responseVerifyEmail: any = await dispatch(verifyEmailCode(email, code, routeState.shouldLoginAfterConfirm == true));
        setLoading(false);

        if (responseVerifyEmail.success !== true) {
            setError(responseVerifyEmail.message);
            return;
        }

        container.success(intl.formatMessage({ id: "confirmarEmail.accountSuccess" }));

        if (routeState.shouldLoginAfterConfirm === false) {
            history.push("/account/logon");
            return;
        }

        if (routeState.previousInCheckout === true) {
            setTimeout(function () {
                window.location.href = Routes.shop.checkout
            }, 1500);
        } else {

            setTimeout(() => {
                history.push(Routes.shop.cart);
            }, 1500);
        }
    }

    const requestNewCode = async () => {
        if (loadingNewCode === true) return;

        setError("");
        setLoadingNewCode(true);

        await Sleep(2000);

        const responseNewEmail = await requestNewEmailLink(email);

        await Sleep(2000);

        setLoadingNewCode(false);
        if (responseNewEmail.success !== true) {
            setError(responseNewEmail.message);
            return;
        }
        setError(intl.formatMessage({ id: "confirmarEmail.newLinkSent" }));
    }

    const loadData = () => {

        if (typeof routeState === "undefined" || typeof routeState.email === "undefined" || routeState.email == null || typeof routeState.email != 'string' || routeState.email.trim().length <= 0) {
            setLoadingEmail(false);
            history.push("/account/logon");
        } else {

            setEmail(routeState.email);
            setLoadingEmail(false);
        }
    }

    useEffect(() => {
        loadData();
        return () => { }
    }, [])

    useEffect(() => {
        if (typeof user !== 'undefined' && user != null && typeof user.userID !== 'undefined') {
            history.replace("/carrinho");
            return;
        }
        return () => { }
    }, [user])


    return (
        <Row>
            <Col xs="12" sm="12" md="12" lg="12">

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                <div className="loginTitleParent">
                    <div className="loginTitle">
                        <FormattedMessage id="confirmarEmail.titulo" />
                    </div>
                </div>

                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs={12} sm={12} md={8} lg={6} xl={4} style={{ fontSize: "13px", fontWeight: "500" }}>

                        <IHTPSpacer verticalSpace='35px' />
                        {loadingEmail === true ? (
                            <>
                                <CircularProgress size={24} className="buttonProgress" />
                            </>
                        ) : (
                            <>
                                <span style={{ color: "rgba(71, 73, 79, 1)" }}><FormattedMessage id="confirmarEmail.texto" /><u><b>{email}</b></u>.
                                </span>


                                <IHTPSpacer verticalSpace='25px' />

                                {error != null && error.length > 0 && (
                                    <>
                                        <div className="emailErrorContainer">
                                            <span>{error}</span>
                                        </div>
                                        <IHTPSpacer verticalSpace="20px" />
                                    </>
                                )}

                                <p style={{ color: "rgba(71, 73, 79, 1)" }}><FormattedMessage id="confirmarEmail.textoEnviarNovoLink" /></p>


                                <span onClick={requestNewCode} className="ihtpLink"
                                    style={{ display: "flex", pointerEvents: loadingNewCode === true ? "none" : "initial", justifyContent: "center", alignItems: "center", alignContent: "center", gap: "0.9rem", color: `${siteInstance.site?.btnBgColour}`, opacity: loadingNewCode === true ? 0.5 : 1 }}>
                                    <FormattedMessage id="confirmarEmail.newLinkButton" />
                                    {loadingNewCode && <CircularProgress size={24} style={{ color: siteInstance.site?.btnBgColour }} className="buttonProgress" />}
                                </span>
                            </>
                        )}
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

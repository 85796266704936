import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getRelatedProductsFromOrder, incrementarProdutoCart } from "../../../actions/miniCartActions";
import RelatedProductItemOrder from './Components/RelatedProductItemOrder';
import "./RelatedProductsToOrder.css";
import { ToastContainer } from "react-toastr";

let container;
class RelatedProductsToOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            loading: true,
            numberDefaultProducts: 3
        }
        this.loadRelatedProducts = this.loadRelatedProducts.bind(this);
    }

    componentDidMount() {
        this.loadRelatedProducts();
    }

    loadRelatedProducts() {

        this.setState(prevState => ({
            ...prevState,
            loading: true
        }));

        getRelatedProductsFromOrder(this.props.hashOrder).then(result => {
            if (result.success === true) {

                var items = result.obj;

                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                    products: items.map(i => {
                        return {
                            itemTitle: i.description,
                            imageURL: i.imageUrl,
                            itemId: i.id,
                            price: i.price,
                            priceToOtherUsers: i.priceToOtherUsers,
                            priceWithoutDiscount: i.priceWithoutDiscount
                        }
                    })
                }));
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        //Se o número de itens no carrinho for diferente do nº anterior ou
        //Se a moeda for diferente da anterior
        if (prevProps.cartNumberItems != this.props.cartNumberItems
            ||
            (typeof prevProps.currencies.selectedDescription != 'undefined' &&
                prevProps.currencies.selectedDescription != this.props.currencies.selectedDescription)
        ) {
            this.loadRelatedProducts();
        }
    }

    renderDefaultProducts() {
        var elements = [];
        for (var i = 0; i < this.state.numberDefaultProducts; i++) {
            elements.push(<RelatedProductItemOrder skeleton={true} key={i} />);
        }

        return elements;
    }

    AddProductToCart = (id) => {
        this.props.dispatch(incrementarProdutoCart(id, "ADD")).then(() => {
            container.clear();
            container.success(this.props.intl.formatMessage({ id: "shop.cart.produtosRelacionados.mensagemAdicionar" }));
        });
    }

    renderRelatedProducts(currency) {
        return (
            this.state.products.map(p => {
                return (<RelatedProductItemOrder itemId={p.itemId} itemTitle={p.itemTitle} imageURL={p.imageURL} key={p.itemId}
                    currency={currency}
                    price={p.price} priceToOtherUsers={p.priceToOtherUsers} priceWithoutDiscount={p.priceWithoutDiscount}
                    //onButtonClick={this.props.onAddProductClick}
                    onButtonClick={this.AddProductToCart}
                />);
            })
        )
    }

    render() {
        const { currencies } = this.props;

        const currency = currencies.selectedDescription;

        var existsRelatedProducts = false;

        if (typeof this.state.products != 'undefined' && this.state.products != null && this.state.products.length > 0) {
            existsRelatedProducts = true;
        }

        return (
            <div className="relatedProductsContainerOrder">
                {this.state.products.length > 0 &&
                    <>
                        <ToastContainer
                            ref={ref => (container = ref)}
                            className="toast-bottom-right"
                        />

                        <div className="relatedProductsTitleParentOrder">
                            <FormattedMessage id="shop.cart.produtosRelacionados.title" />
                        </div>

                        <div className="relatedProductsItemsContainerOrder">

                            {this.state.loading === true ? (
                                this.renderDefaultProducts()
                            ) : (
                                this.renderRelatedProducts(currency)
                            )}

                        </div>
                    </>
                }
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        currencies: state.currencies,
        cartNumberItems: state.cartNumberItems
    };
};

export default injectIntl(connect(mapStateToProps)(RelatedProductsToOrder));
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiteFactoryClass } from '../../classes/SiteFactory';
import { SocialLoginPropsDTO } from '../../models/SocialLoginPropsDTO';
import FacebookLoginComponent from './FacebookLoginComponent';
import GoogleLoginComponent from './GoogleLoginComponent';

const siteInstance = SiteFactoryClass.GetInstance();

export default function SocialLogin(props: SocialLoginPropsDTO) {

    const [googleErrorMessage, setGoogleErrorMessage] = useState<string>("");

    return (
        <>
            {siteInstance.site?.hasFacebookLogin || siteInstance.site?.hasGoogleLogin &&
                <>
                    <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                        {siteInstance.site.hasFacebookLogin &&
                            <Col xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} className="colsFBButton">
                                <FacebookLoginComponent previousInCheckout={props.previousInCheckout}
                                    showText={props.showText}
                                    isRegisteringInCheckout={props.isRegisteringInCheckout}
                                    callbackToRegisterInCheckout={props.callbackToRegisterInCheckout} />
                            </Col>
                        }

                        {siteInstance.site.hasGoogleLogin &&
                            <Col xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} className="colsGButton">
                                <GoogleLoginComponent
                                    onErrorMessage={(message) => setGoogleErrorMessage(message)}
                                    previousInCheckout={props.previousInCheckout}
                                    showText={props.showText}
                                    isRegisteringInCheckout={props.isRegisteringInCheckout}
                                    callbackToRegisterInCheckout={props.callbackToRegisterInCheckout} />
                            </Col>
                        }
                    </Row>

                    {
                        googleErrorMessage != null && googleErrorMessage.length > 0 ?
                            <Row style={{ marginTop: "25px" }}>
                                <Col>
                                    <label style={{ fontSize: "15px" }}>{googleErrorMessage}</label>
                                </Col>
                            </Row>
                            : null
                    }
                </>
            }
        </>
    )
}

import TawkToInfo from "./TawkToInfo"

export type SiteClassProps = {
    faviconName: string,
    btnBgColour: string,
    siteName: string
    headerColour: string
    loadingImage: string,
    logo: string,
    email: string,
    mbWayPaymentName: string,
    hasFacebookLogin: boolean,
    hasGoogleLogin: boolean
}
    & (
        {
            hasSocialMedia: false
        }
        | {
            hasSocialMedia: true,
            socialMediaInfo: SocialMediaInfo
        }
    )

interface SocialMediaInfo {
    hashTag: string,
    facebookLink: string,
    youtubeLink: string,
    linkedinLink: string,
    instagramLink: string
}

export default class SiteClass {
    faviconName: string = "";
    btnBgColour: string = "";
    siteName: string = "";
    headerColour: string = "";
    loadingImage: string = "";
    logo: string = "";
    email: String = "";
    hasFacebookLogin: boolean = false;
    hasGoogleLogin: boolean = false;
    hasSocialMedia = false;
    storeUrl: string = "";
    mainSiteUrl: string = "";
    socialMediaInfo: SocialMediaInfo | undefined;
    mbWayPaymentName: string = "";
    tawkToInfo: TawkToInfo | undefined;

    constructor(props: SiteClassProps) {
        this.faviconName = props.faviconName;
        this.btnBgColour = props.btnBgColour;
        this.siteName = props.siteName;
        this.headerColour = props.headerColour;
        this.loadingImage = props.loadingImage;
        this.logo = props.logo;
        this.email = props.email;
        this.mbWayPaymentName = props.mbWayPaymentName;
        this.hasFacebookLogin = props.hasFacebookLogin;
        this.hasGoogleLogin = props.hasGoogleLogin;
        this.hasSocialMedia = props.hasSocialMedia;

        if (props.hasSocialMedia) {
            this.socialMediaInfo = props.socialMediaInfo
        }
    }

    ChangeSiteNameAndFavicon() {
        document.title = `SHOP | ${this.siteName}`;
        const link: any = document.querySelector('link[rel="icon"]');

        if (link) {
            link.href = this.faviconName;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.type = 'image/png';
            newLink.href = this.faviconName;
            document.head.appendChild(newLink);
        }

        // Update shortcut icon
        const shortcutIconLink: any = document.querySelector('link[rel="shortcut icon"]');
        if (shortcutIconLink) {
            shortcutIconLink.href = this.faviconName; // Replace with your dynamic URL
        } else {
            const newShortcutIconLink = document.createElement('link');
            newShortcutIconLink.rel = 'shortcut icon';
            newShortcutIconLink.href = this.faviconName; // Replace with your dynamic URL
            document.head.appendChild(newShortcutIconLink);
        }

        // Update Apple Touch Icon
        const appleTouchIconLink: any = document.querySelector('link[rel="apple-touch-icon"]');
        if (appleTouchIconLink) {
            appleTouchIconLink.href = this.faviconName; // Replace with your dynamic URL
        } else {
            const newAppleTouchIconLink = document.createElement('link');
            newAppleTouchIconLink.rel = 'apple-touch-icon';
            newAppleTouchIconLink.href = this.faviconName // Replace with your dynamic URL
            document.head.appendChild(newAppleTouchIconLink);
        }
    }
}
import { ImCheckmark } from "react-icons/im";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import RegisterBenefitsLabel from "./RegisterBenefitsLabel";

export default function RegisterBenefitsIhtp() {
    const user = useSelector((state: any) => state.user);
    const intl = useIntl();

    return (
        <>
            {
                user.language?.toUpperCase() != "EN" ?
                    <RegisterBenefitsLabel
                        icon={<ImCheckmark color="green" />}
                        text={intl.formatMessage({ id: "registar.vantagens.1" })}
                        className={"benefitsLabel"} />
                    : <></>
            }

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.2" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.3" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.4" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.5" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.6" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.vantagens.7" })}
                className={"benefitsLabel"} />
        </>
    )
}
import { useIntl } from "react-intl";
import RegisterBenefitsLabel from "./RegisterBenefitsLabel";

export default function RegisterBenefitsTitle() {
    const intl = useIntl();

    return (
        <RegisterBenefitsLabel
            text={intl.formatMessage({ id: "registar.vantagens.titulo" })}
            className={"benefitsHeader"} />
    )
}
import { Component } from 'react';
import tawkTo from "tawkto-react";
import { SiteFactoryClass } from '../../classes/SiteFactory';

const siteInstance = SiteFactoryClass.GetInstance();

export default class TawkTo extends Component {
    componentDidMount() {
        var tawkToKey = siteInstance.site.tawkToInfo.info[0];
        var tmp = siteInstance.site.tawkToInfo.info.find(t => t.language == this.props.locale);

        if (tmp != null) {
            tawkToKey = tmp.key;
        }

        tawkTo(siteInstance.site.tawkToInfo.propertyId, tawkToKey)
    }

    render() {
        return null;
    }
}
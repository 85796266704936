import { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import SiteLinksHelper from '../../../../helpers/SiteLinksHelper';

class MainPageButton extends Component {
    render() {
        return (
            <a href={SiteLinksHelper.links.mainSiteUrl} className="headerLinks">
                <span>
                    <FormattedMessage id="header.mainPage" />
                </span>
            </a>
        )
    }
}
export default injectIntl(MainPageButton);
import "bootstrap";
import $ from "jquery";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { getSite } from "./actions/geralActions";
import { fetchLanguageFunction, getUser } from "./actions/miniCartActions";
import "./index.css";
import { default as IntlProviderWrapper } from './IntlProviderWrapper';
import reducer from "./reducers/index";
import * as serviceWorker from "./serviceWorker";
import { SiteFactoryClass } from "./classes/SiteFactory";

window.jQuery = $;
window.$ = $;

const store = createStore(reducer, applyMiddleware(thunk));
const siteFactory = SiteFactoryClass.GetInstance();

store.dispatch(getSite())
  .then((result) => {
    siteFactory.SetSite(result.toLowerCase());
    siteFactory.site.ChangeSiteNameAndFavicon();
  })
  .then(getUser)
  .then((user) => {
    return new Promise((resolve) => {
      store.dispatch(fetchLanguageFunction()).then((languages) => {
        const availableLanguages = languages.languages;
        resolve({ user, availableLanguages })
        return;
      })
    })
  })
  .then((info) => {
    ReactDOM.render(
      <Provider store={store}>
        <IntlProviderWrapper user={info.user} languages={info.availableLanguages.list} />
      </Provider>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

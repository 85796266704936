import $ from "jquery";
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { changeCurrencyFE, logout, sideBarMenu } from "../../../actions/miniCartActions";
import { SiteFactoryClass } from "../../../classes/SiteFactory";
import CrossDomainEventHandler from "../../../helpers/CrossDomainEventHandler";
import Routes from "../../../helpers/Routes"; // Caminhos para as páginas
import SiteLinksHelper from "../../../helpers/SiteLinksHelper";
import SelectCurrency from "../Header/Components/SelectCurrency";
import SelectLanguage from "../Header/Components/SelectLanguage";
import CustomMenuItem from "./Components/CustomMenuItem";
import BeConsultantIcon from "./Icons/BeConsultantIcon";
import BlogIcon from "./Icons/BlogIcon";
import CloseIcon from "./Icons/CloseIcon";
import ContactsIcon from "./Icons/ContactsIcon";
import HomeIcon from "./Icons/HomeIcon";
import LogoutIcon from "./Icons/LogoutIcon";
import ProfileIcon from "./Icons/ProfileIcon";
import RegisterIcon from "./Icons/RegisterIcon";
import ShopIcon from "./Icons/ShopIcon";
import "./Menu.css";

const siteInstance = SiteFactoryClass.GetInstance();

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currencies: [],
      textoPesquisa: {
        texto: ""
      }
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleInputPesquisar = this.handleInputPesquisar.bind(this);
  }

  logoutAccount() {
    this.props.dispatch(logout()).then(() => {
      CrossDomainEventHandler.sendEvent();
      window.location = "/Loja"
    });
  }

  handleInput(e) {
    let value = e.target.value;
    this.props.dispatch(changeCurrencyFE(value));
  }

  handleInputPesquisar(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        textoPesquisa: {
          ...prevState.textoPesquisa,
          [name]: value
        }
      }),
      () => console.log(this.state.textoPesquisa)
    );
  }

  componentDidMount() {
    // this.props.dispatch(fetchCurrencyFunction());
  }

  onSetSidebarOpen(open) {
    this.props.dispatch(sideBarMenu(open));
    $("#sidebarMenu").css("height", "0px");
  }

  onchangeSearchShop = () => {
    window.location =
      "/Loja/Catalogo/PES?search=" + this.state.textoPesquisa.texto;
  };

  render() {
    const { user, open, currencies } = this.props;
    return (
      <div className="bp3-md-menu">

        {/* Parte de cima título e icon para fechar */}
        <Row className="sidebarMenuTitleContainer">

          {/* Empty Space */}
          <Col xs="2" sm="2" md="4" lg="4" />

          {/* Título */}
          <Col xs="8" sm="8" md="4" lg="4">
            <a href={Routes.home.home}>
              <img
                src={siteInstance.site.loadingImage}
                className="bp3-md-menu-logoihtpweb05sombra1"
              />
            </a>
          </Col>

          {/* Símbolo de fechar */}
          <Col xs="1" sm="1" md="4" lg="4" onClick={() => this.onSetSidebarOpen(false)}>
            <CloseIcon />
          </Col>

        </Row>
        <br />
        <br />
        <br />
        <Container>
          {user.userID ? (
            <div>

              {/* Menu MyIHTP */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<ProfileIcon />}
                    link={process.env.REACT_APP_MY_WEBSITE_URL}
                    labelId={"header.Perfil"}
                  />
                </Col>
              </Row>

              <br />

              {/* Menu Sair */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    icon={<LogoutIcon />}
                    link={null}
                    labelId={"header.Sair"}
                    isClickable={true}
                    onClick={() => this.logoutAccount()}
                  />
                </Col>
              </Row>

            </div>
          ) : (
            <div>

              {/*Menu de Entrar */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<RegisterIcon />}
                    link={Routes.account.login}
                    labelId={"header.Entrar"}
                  />
                </Col>
              </Row>
              <br />

              {/* Menu de Registar */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<RegisterIcon />}
                    link={Routes.account.registo}
                    labelId={"header.Registar"}
                  />
                </Col>
              </Row>

            </div>
          )}
        </Container>
        <br />
        <br />
        <Container>

          {/* Menu Inicial */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<HomeIcon />}
                link={SiteLinksHelper.links.mainSiteUrl}
                labelId={"header.mainPage"}
              />
            </Col>
          </Row>

          <p />

          {/* Menu Loja */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<ShopIcon />}
                link={SiteLinksHelper.links.storeUrl}
                labelId={"header.Loja"}
              />
            </Col>
          </Row>

          <p />

          {SiteLinksHelper.links.hasBlog &&
            <>
              {/* Menu Blog */}
              < Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<BlogIcon />}
                    link={SiteLinksHelper.links.blogUrl}
                    labelId={"header.Blog"}
                  />
                </Col>
              </Row>
            </>
          }

          <p />

          {SiteLinksHelper.links.hasBeingConsultant &&
            <>
              {/* Menu Ser Consultor */}
              <Row className="sidebarMenuItemContainer">
                <Col xs="5" sm="5" md="3" lg="3">
                  <CustomMenuItem
                    isClickable={false}
                    icon={<BeConsultantIcon />}
                    link={SiteLinksHelper.links.beingConsultantUrl}
                    labelId={"header.Serconsultor"}
                  />
                </Col>
              </Row>
            </>}
          <p />

          {/* Menu Contactos */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <CustomMenuItem
                isClickable={false}
                icon={<ContactsIcon />}
                link={SiteLinksHelper.links.contactsUrl}
                labelId={"header.Contactos"}
              />
            </Col>
          </Row>

          <p />

          <br />
          {/* Selecionar a moeda */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3" style={{ margin: "0px" }}>
              <SelectCurrency />
            </Col>
          </Row>

          <p />

          {/* Parte de selecionar idioma */}
          <Row className="sidebarMenuItemContainer">
            <Col xs="5" sm="5" md="3" lg="3">
              <SelectLanguage isMobile={isMobile} callback={
                () => this.onSetSidebarOpen(false)
              } />
            </Col>
          </Row>

          <br />
        </Container>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default injectIntl(connect(mapStateToProps)(Home));

import { Button, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setIsGuestCheckout } from "../../../../actions/miniCartActions";
import { SiteFactoryClass } from "../../../../classes/SiteFactory";
import Routes from "../../../../helpers/Routes";
import "../Cart.css";

const siteInstance = SiteFactoryClass.GetInstance();

export default function CartOrderButtons() {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);

    return (
        <>
            {/* Botão de Encomenda */}
            <Row style={{ justifyContent: "center" }}>
                <Col xl={3} md={6}>
                    <NavLink to={Routes.shop.checkout} onClick={() => dispatch(setIsGuestCheckout(false))}>
                        <Button className="cartCheckoutButton" style={{ backgroundColor: siteInstance.site?.btnBgColour }}>
                            <FormattedMessage id="shop.cart.carrinho.encomendar" />
                        </Button>
                    </NavLink>
                </Col>

                {!user.userID &&
                    <Col xl={3} md={6}>
                        <NavLink to={Routes.shop.checkout} onClick={() => dispatch(setIsGuestCheckout(true))}>
                            <Button className="cartCheckoutButton" style={{ backgroundColor: siteInstance.site?.btnBgColour }}>
                                <FormattedMessage id="shop.cart.carrinho.encomendarSemLogin" />
                            </Button>
                        </NavLink>
                    </Col>
                }
            </Row>

            <Row style={{ justifyContent: "center" }}>
                <Col xl={3} md={12}>
                    <NavLink to={Routes.shop.home}>
                        <Button className="cartCheckoutButtonContinueBuy" style={{ backgroundColor: siteInstance.site?.headerColour }}>
                            {"< "}<FormattedMessage id="shop.cart.carrinho.continuarComprar"></FormattedMessage>
                        </Button>
                    </NavLink>
                </Col>
            </Row>
        </>
    )
}
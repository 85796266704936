import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { FlatButton } from "material-ui";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTypes, selectPaymentPhoneNumber, selectPaymentType } from "../../actions/miniCartActions";
import { addPhoneNumber, getPhoneNumbers, updateDefaultPaymentType } from "../../actions/userActions";
import { checkout_MetodoPagamento } from "../../Analytics/DataLayer";
import { SiteFactoryClass } from "../../classes/SiteFactory";
import Toast from "../../helpers/Toast";
import { CreateProductObjectToSendToGoogleAnalytics, HasError, IfNullOrUndefinedReturnDefault, SetUserDefaultValues } from "../../Utils/UtilsCheckout";
import IHTPButton from "../Common/IHTPButton";
import { IHTPSelectItem } from "../Common/IHTPSelect";
import Button from "../Forms/Button";
import useCheckoutDisabledInputs from "./useCheckoutDisabledInputs";

const styleLabel: CSSProperties = {
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center"
}

export interface TelemovelDTO {
  Id: number,
  Descricao: string
}

const siteInstance = SiteFactoryClass.GetInstance();

export default function TiposPagamento() {
  const [pagamentoEmParcelas, setPagamentoEmParcelas] = useState(false);
  const [parcelas, setParcelas] = useState<IHTPSelectItem[]>([]);
  const [parcelaEscolhida, setParcelaEscolhida] = useState<number>(1);
  const [pagamentoPorTelemovel, setPagamentoPorTelemovel] = useState<boolean>(false);
  const [idTelemovelSelecionado, setIdTelemovelSelecionado] = useState<number>(0);
  const [numeroTelemovelSelecionado, setNumeroTelemovelSelecionado] = useState<string | null>(null);
  const [telemoveis, setTelemoveis] = useState<TelemovelDTO[]>([]);
  const [criarTelemovel, setCriarTelemovel] = useState<boolean>(false);
  const [novoTelemovelNumero, setNovoTelemovelNumero] = useState<string>("");
  const [novoTelemovelIndicativo, setNovoTelemovelIndicativo] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isChangingDefaultPayment, setIsChangingDefaultPayment] = useState<boolean>(false);
  const [loadDefaultPhoneNumber, setLoadDefaultPhoneNumber] = useState<boolean>(false);
  const { disabledFields } = useCheckoutDisabledInputs();
  const cart = useSelector((state: any) => state.cart);

  const dispatch = useDispatch();
  const intl = useIntl();

  const selectedPaymentType: string = useSelector((state: any) => state.checkoutData.selectedPaymentType);
  const checkoutData = useSelector((state: any) => state.checkoutData);
  const errorCode = useSelector((state: any) => state.errorCode);
  const paymentTypes = useSelector((state: any) => state.paymentTypes);
  const userCheckoutDefaultValues = useSelector((state: any) => state.userCheckoutDefaultValues);
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    dispatch(getPaymentTypes());
  }, [])

  useEffect(() => {
    let hasError = HasError(errorCode, ["Pagamento"]);
    if (hasError !== errorCode) {
      setHasError(hasError);

      if (hasError === true) {
        var p = document.getElementById("pagamento");
        if (p != null) p.scrollIntoView({ block: "center" });
      }
    }
  }, [errorCode]);

  useEffect(() => {

    if (userCheckoutDefaultValues.paymentType != null && selectedPaymentType == null) {
      loadDefaultPayment();
    }

  }, [userCheckoutDefaultValues.paymentType, paymentTypes])

  const loadDefaultPayment = async () => {
    if (typeof paymentTypes === "undefined" || paymentTypes == null || paymentTypes.length <= 0) return;

    //Já se escolheu um pagamento
    if (selectedPaymentType != null) return;

    const currentPayment = paymentTypes.find(s => s.ID_TipoPagamento == userCheckoutDefaultValues.paymentType);
    if (currentPayment == null) return;

    handleChangePaymentType(null, null, userCheckoutDefaultValues.paymentType);

    if (userCheckoutDefaultValues != null && (userCheckoutDefaultValues.telephone == null || userCheckoutDefaultValues?.telephone == "")) {
      return;
    }

    //Se o utilizador tiver número selecionado, carregamos os números e mandamos signal para que assim que possível se selecione o nº default
    loadPhoneNumbers();
    setLoadDefaultPhoneNumber(true);
  }

  useEffect(() => {

    //Para carregar o número de pagamento default, o pagamento deve estar selecionado, devemos ter os telemóveis e deve-se ter colocado a flag a true
    if (loadDefaultPhoneNumber === true && selectedPaymentType != null && Array.isArray(telemoveis) && telemoveis.length > 0) {
      loadDefaultPhoneNumberToPayment();
    }

  }, [loadDefaultPhoneNumber, telemoveis, selectedPaymentType])

  const loadDefaultPhoneNumberToPayment = () => {
    var tmpTelemoveis = telemoveis;

    //Se não tivermos telemóveis carregamos
    if (typeof tmpTelemoveis === "undefined" || tmpTelemoveis == null || tmpTelemoveis.length <= 0) return;

    var currentTelephone = tmpTelemoveis.find(a => a.Descricao == userCheckoutDefaultValues.telephone);
    if (currentTelephone == null) return;

    handleChangePhoneNumber(currentTelephone.Id);

    //Dizemos que já não precisa de carregar os dados default
    setLoadDefaultPhoneNumber(false);
  }

  //Guardar o valor do novo telemovel
  const handleChangeNewPhoneNumber = async (value: any, data: any, event: any, formattedValue: any) => {
    var dialCode = data.dialCode;
    var rawPhone = value.slice(data.dialCode.length)

    setNovoTelemovelIndicativo(dialCode);
    setNovoTelemovelNumero(rawPhone);

    if (user.userID == null || typeof user.userID == 'undefined') {
      var phoneNumber = dialCode + " " + rawPhone;

      dispatch(selectPaymentType(selectedPaymentType, parcelaEscolhida, phoneNumber));
    }
  }

  const handleChangeInstallments = async (evento: any, index: any, value: any) => {
    var phoneNumber = idTelemovelSelecionado;

    dispatch(selectPaymentType(selectedPaymentType, value, phoneNumber));

    setParcelaEscolhida(value);
  }

  //Método para permitir escolher tlm perante os valores existentes
  const handleChangePhoneNumber = async (value2: any) => {
    var numero: string = "";
    var tmpNumero = telemoveis.find(t => t.Id == value2);
    if (tmpNumero != null) {
      numero = tmpNumero.Descricao;
    }

    //Se numero estiver a null, significa que não encontramos o id do numero selecionado, 
    //nos numeros que vieram do servidor, por isso os dados não terão sido carregados corretamente
    //algo que nunca deve ocorrer, mas só para precaver
    if (numero == null) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.servidor" }));
      return;
    }

    var numeroComIndicativo = numero.trimStart().trimEnd().split(" ");

    //Verificamos se é um número válido
    //Se não tiver length de 2 significa que não está no formato: {indicativoPais} {numero}
    if (numeroComIndicativo.length !== 2) {
      Toast.Show("error", `${intl.formatMessage({ id: "pagamento.alterarTipoPagamento.numeroTelemovelInvalido.p1" })} ${intl.formatMessage({ id: "pagamento.alterarTipoPagamento.numeroTelemovelInvalido.p2" })}`)


      setIdTelemovelSelecionado(0);
      setNumeroTelemovelSelecionado(null);

      dispatch(selectPaymentPhoneNumber(null));
    } else {
      dispatch(selectPaymentType(selectedPaymentType, parcelaEscolhida, numero));

      setIdTelemovelSelecionado(value2)
      setNumeroTelemovelSelecionado(numero)
    }
  }

  //Método para adicionar novo número de telemóvel
  const addNewPhoneNumber = async () => {
    try {
      if (novoTelemovelIndicativo === "351" && novoTelemovelNumero?.length !== 9) {
        Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.telemovelInvalido" }));
        return;
      }

      if (novoTelemovelIndicativo == null || novoTelemovelNumero == null) {
        Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.telemovelInvalido" }));
        return;
      }

      var phoneNumber = novoTelemovelIndicativo + " " + novoTelemovelNumero;
      var resultAddPhoneNumber = await addPhoneNumber(phoneNumber);

      if (resultAddPhoneNumber.success !== true) {
        Toast.Show("error", resultAddPhoneNumber.message);
        return;
      }

      loadPhoneNumbers();

      setCriarTelemovel(false);
      setIdTelemovelSelecionado(resultAddPhoneNumber.obj);

      dispatch(selectPaymentPhoneNumber(phoneNumber));

      Toast.Show("success", intl.formatMessage({ id: "pagamento.sucesso.adicionarTelemovel" }));
    }
    catch (err) {
      Toast.Show("error", intl.formatMessage({ id: "pagamento.erro.erroAoAdicionarTelemovel" }));
    };
  }

  const loadPhoneNumbers = async () => {
    try {
      var result = await getPhoneNumbers();
      var tmpTelemoveis = [];
      if (result.success === true) {
        tmpTelemoveis = result.obj
      }

      setTelemoveis(tmpTelemoveis)
      return tmpTelemoveis;
    } catch (exp) {
      setTelemoveis([])
      return [];
    }
  }


  //Método para abrir o formulário de adicionar telemóvel
  const clickToAddPhoneNumberForm = async () => {
    setCriarTelemovel(true);
    setIdTelemovelSelecionado(0);
    setNumeroTelemovelSelecionado(null);

    dispatch(selectPaymentPhoneNumber(null));
  }

  //Método para esconder o formulário de adicionar telemóvel
  const clickToHidePhoneNumberForm = () => {
    setCriarTelemovel(false)
  }

  //Método para alterar o tipo de pagamento
  const handleChangePaymentType = async (event: any, index: any, value: any) => {
    //Procuramos o pagamento atual selecionado
    var currentPayment = paymentTypes.find(p => p.ID_TipoPagamento === value);

    //Verificamos se é um pagamento em parcelas
    var pagamentoEmParcelas = (currentPayment.EmParcelas === true);

    //Verificamos se é um pagamento por telemovel
    var numeroTelemovel = 0;
    var isPhonePayment: boolean = (currentPayment.PagamentoPorTelemovel === true);

    //Adicionamos o número de parcelas
    var parcelas: IHTPSelectItem[] = [];
    for (let i = 1; i <= currentPayment.NMaxParcelas; i++) {
      parcelas.push({ value: i, label: "" });
    }

    //Se tivermos pagamento por telemovel definido, vamos buscar os numeros
    if (isPhonePayment === true) {
      loadPhoneNumbers();
    }

    dispatch(selectPaymentType(value, 1, null));

    setPagamentoEmParcelas(pagamentoEmParcelas);
    setParcelas(parcelas);
    setParcelaEscolhida(1);
    setPagamentoPorTelemovel(isPhonePayment);
    setIdTelemovelSelecionado(numeroTelemovel);
    setNumeroTelemovelSelecionado(null);
    sendOrderProductsAndPaymentTypeToGoogleAnalytics(value);
  }

  const sendOrderProductsAndPaymentTypeToGoogleAnalytics = (paymentType: any) => {
    var items = CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);

    checkout_MetodoPagamento(items, paymentType);
  }

  const onUpdateDefaultPaymentClick = async () => {

    if (pagamentoPorTelemovel == true && (idTelemovelSelecionado == null || idTelemovelSelecionado == 0)) {
      Toast.Show("error", "É necessário escolher um número.");
      return;
    }

    var numeroTelemovel = telemoveis.find(a => a.Id == idTelemovelSelecionado);

    setIsChangingDefaultPayment(true);
    var resultUpdatePaymentType = await updateDefaultPaymentType(selectedPaymentType, numeroTelemovel != null ? numeroTelemovel.Descricao : "");
    setIsChangingDefaultPayment(false);

    if (resultUpdatePaymentType.success === false) {
      Toast.Show("error", resultUpdatePaymentType.message);
      return;
    }

    if (resultUpdatePaymentType.success === true) {
      Toast.Show("success", intl.formatMessage({ id: "pagamento.padraoSucesso" }));

      await SetUserDefaultValues(dispatch, userCheckoutDefaultValues.transportType, selectedPaymentType, numeroTelemovelSelecionado != null ? numeroTelemovelSelecionado : "");
    }
  };

  return (
    <div>
      <div className={hasError === true ? "checkoutBox errorBorder"
        : !checkoutData.confirmOrderClicked && checkoutData.selectPaymentTypes
          ? "checkoutBox doneSelected"
          : checkoutData.confirmOrderClicked
            ? checkoutData.selectPaymentTypes
              ? "checkoutBox doneSelected"
              : "checkoutBox discountSelected"
            : "checkoutBox"
      }
        id="pagamento">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }} className="checkoutBoxTitle">
            <FormattedMessage id="pagamento.titulo" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="paymentType" style={{ marginTop: "1em" }}>
            <SelectField
              hintText={intl.formatMessage({ id: "pagamento.acao" })}
              hintStyle={{ paddingLeft: "0.5em" }}
              value={selectedPaymentType}
              onChange={handleChangePaymentType}
              fullWidth={true}
              labelStyle={{ paddingRight: "0px" }}
              className="SelectField"
              disabled={disabledFields}
            >
              {paymentTypes.map((paymentType, j) => {
                return paymentType.ID_TipoPagamento !== "NA" ? (
                  <MenuItem value={paymentType.ID_TipoPagamento} primaryText={paymentType.Descricao} />
                ) : null;
              })}
            </SelectField>
            {pagamentoEmParcelas === true ?
              <SelectField
                floatingLabelText={intl.formatMessage({ id: "pagamento.parcelas" })}
                fullWidth={true}
                className="SelectField" onChange={handleChangeInstallments} value={parcelaEscolhida}>
                {parcelas.length > 0 ?
                  (parcelas.map(item => {
                    return <MenuItem
                      value={item.value}
                      primaryText={item.value + "" + ((item.value < 2) ? " Mês" : " Meses")}
                    />
                  }))
                  : (null)
                }
              </SelectField>
              : null}


            {/* Adicionar telemóvel MBWAY*/}
            <div hidden={!pagamentoPorTelemovel} style={{ margin: "10px", padding: "10px", backgroundColor: "white", border: "1px solid grey" }}>
              <span style={styleLabel}>
                {intl.formatMessage({
                  id: "pagamento.telemovel"
                })}
              </span>

              {/* Botão para adicionar um novo numero de telemovel*/}
              <Row>
                <Col>
                  <FlatButton
                    label={
                      <svg
                        height="25px"
                        viewBox="0 0 512 512"
                        width="25px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                        <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                        <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
                      </svg>
                    }
                    secondary={true}
                    onClick={clickToAddPhoneNumberForm}
                  />
                </Col>
              </Row>

              <br />
              {criarTelemovel !== true ? (
                <Row>
                  <Col>
                    {telemoveis.length > 0 ? (
                      <SelectField
                        floatingLabelText={intl.formatMessage({ id: "pagamento.selecionarTelemoveis" })}
                        value={idTelemovelSelecionado}
                        onChange={(_1, _2, value) => handleChangePhoneNumber(value)}
                        fullWidth={true}
                        style={{ fontWeight: "bold", textAlign: "center", paddingRight: "0px" }}
                        className="SelectField"
                        labelStyle={{ paddingRight: "0px" }}>
                        <MenuItem value={0} primaryText={intl.formatMessage({ id: "pagamento.selecioneUmTelemovel" })}
                        />
                        {telemoveis.map((tlm, j) => {
                          return (
                            <MenuItem value={tlm.Id} primaryText={tlm.Descricao} />
                          );
                        })}
                      </SelectField>
                    ) : null}
                  </Col>
                </Row>
              ) : (
                <div>
                  <label><FormattedMessage id="pagamento.adicionarNumeroTelemovel" /></label>
                  <Row>
                    <Col>
                      <PhoneInput searchPlaceholder={intl.formatMessage({
                        id: "pagamento.procurarTelemovel"
                      })}
                        placeholder={intl.formatMessage({
                          id: "pagamento.introduzirNumeroTelemovelPlaceholder"
                        })}
                        country="pt"
                        copyNumbersOnly={false}
                        enableSearch={true} onChange={handleChangeNewPhoneNumber}
                      />
                    </Col>
                  </Row>

                  {user.userID != null && typeof user.userID != 'undefined' && (
                    <Button
                      action={addNewPhoneNumber}
                      type="primary"
                      title={intl.formatMessage({
                        id: "pagamento.guardarNumeroTelemovelButton"
                      })}
                      style={{ marginTop: "10px", maxWidth: "100%", whiteSpace: "normal" }}
                    />)}

                  <Button
                    action={clickToHidePhoneNumberForm}
                    title={intl.formatMessage({
                      id: "pagamento.cancelarButton"
                    })}
                    style={{ marginTop: "10px", marginLeft: "5px" }}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>

        {typeof selectedPaymentType !== 'undefined' && selectedPaymentType != null && user.userID != null && typeof user.userID != 'undefined' && (
          <Row style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
              {//Se o tipo de pagamento selecionado for diferente do default OU se o número default for diferente so numero selecionado
                //mostra o botão
                userCheckoutDefaultValues?.paymentType != selectedPaymentType
                  || IfNullOrUndefinedReturnDefault(userCheckoutDefaultValues?.telephone, "") != IfNullOrUndefinedReturnDefault(numeroTelemovelSelecionado, "") ? (
                  <IHTPButton text={intl.formatMessage({ id: "pagamento.padraoBotao" })} onClick={onUpdateDefaultPaymentClick} loading={isChangingDefaultPayment}
                    //Se o tipo de pagamento for MBWAY e não tiver nenhum número selecionado, o botão fica disabled
                    disabled={selectedPaymentType == "MW" && (idTelemovelSelecionado == 0 || idTelemovelSelecionado == null || typeof idTelemovelSelecionado == "undefined")
                      ? true : false} />
                ) : (
                  <span style={{
                    textAlign: "right",
                    fontFamily: "Montserrat", fontWeight: 600, fontSize: "13px", color: siteInstance.site?.btnBgColour
                  }}><FormattedMessage id="pagamento.padraoSelecionado" /></span>
                )}
            </Col>
          </Row>
        )}
      </div>
    </div >
  );
};
import $ from "jquery";
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { changeCurrencyFE, changeLanguageFE, fetchCartNumberItems, fetchCurrencyFunction, fetchUser, setLoadingAllPageContent, sideBarMenu } from "../../../actions/miniCartActions";
import MenuDesktop from "../Menu/Menu";
import CartHeader from "./Components/CartHeader";
import { HeaderColour } from "./Components/HeaderColour";
import LoginButton from "./Components/LoginButton";
import LogoutButton from "./Components/LogoutButton";
import MainPageButton from "./Components/MainPageButton";
import ProfileButton from "./Components/ProfileButton";
import SelectCurrency from "./Components/SelectCurrency";
import SelectLanguage from "./Components/SelectLanguage";
import ShopButton from "./Components/ShopButton";
import "./Header.css";
import LogoHeader from "./LogoHeader";

class Header extends Component {
  constructor(props) {
    super(props);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputLingua = this.handleInputLingua.bind(this);
  }

  onSetSidebarOpen(open) {
    this.props.dispatch(sideBarMenu(open));
    $("#sidebarMenu").css("height", "100vh");
  }

  componentDidMount() {

    this.props.dispatch(fetchCartNumberItems());
    this.props.dispatch(fetchCurrencyFunction());
    this.props.dispatch(fetchUser());
  }
  handleInput(e) {
    let value = e.target.value;
    this.props.dispatch(changeCurrencyFE(value));
  }

  async handleInputLingua(e) {
    let value = e.target.value;
    this.props.dispatch(setLoadingAllPageContent(true));
    await this.props.dispatch(changeLanguageFE(value));
  }

  render() {
    const { user, sidebarmenu, site } = this.props;

    return (
      <div className="headerMenu" id="sidebarMenu">
        <Sidebar
          sidebar={<MenuDesktop />}
          open={sidebarmenu}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "white", zIndex: "10000" } }}
          shadow={true}
        />
        <Container className="headerMainContainer"
          style={{
            backgroundColor: HeaderColour(user)

          }}>
          <Row className="headerMainSubContainer">

            {/* Parte central que tem logotipo do site */}
            <Col xs={8} sm={8} md={6} lg={3} xl={2} className="headerTitleContainer">
              <LogoHeader onClick={() => this.onSetSidebarOpen(true)} />
            </Col>

            <Col xs={6} sm={4} md={6} lg={2} xl={2} className="headerNewSiteMenusContainer">
              <Row className="headerNewSiteMenusSubContainer">
                <Col xs={6} sm={6} md={5} className="headerNewSiteMainLinkContainer">
                  <MainPageButton />
                </Col>
                <Col xs={6} sm={6} md={5} className="headerNewSiteStoreLinkContainer">
                  <ShopButton />
                </Col>
              </Row>
            </Col>

            <Col md={2} lg={2} xl={3} className="headerEmptySpace">
            </Col>

            {/* Parte que tem os outros botões da navbar*/}
            <Col xs={4} sm={4} md={6} lg={5} xl={5} className="headerActionsMenusContainer">

              {/* Botão de selecionar idioma */}
              {isMobile ? null : <SelectLanguage />}

              <SelectCurrency />

              <CartHeader />

              <ProfileButton />

              {user.userID ? (
                <a class="fake-link" id="fake-link-1" style={{ textDecoration: "none" }} >
                  <LogoutButton />
                </a>
              ) : null}

              <LoginButton />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    sidebarmenu: state.sideBarMenu,
    site: state.site
  };
};

export default withRouter(connect(mapStateToProps)(Header));

import SiteClass, { SiteClassProps } from "./Site";
import TawkToInfo from "./TawkToInfo";

class IhtpSiteClass extends SiteClass {
    constructor() {
        const props: SiteClassProps = {
            faviconName: "/ihtp.ico",
            btnBgColour: "#CA006A",
            siteName: "I Have The Power",
            headerColour: "#034275",
            loadingImage: "/assets/images/md-logo-ihtpweb-05sombra-1.png",
            logo: "/assets/images/logo-ihtp.svg",
            email: "ihavethepower@solfut.com",
            hasSocialMedia: true,
            mbWayPaymentName: "Ihtp",
            hasFacebookLogin: true,
            hasGoogleLogin: true,
            socialMediaInfo: {
                hashTag: "#IHTPGLOBAL",
                facebookLink: "https://www.facebook.com/IHTPGLOBAL/",
                instagramLink: "https://www.instagram.com/ihavethepowerglobal",
                linkedinLink: "https://www.linkedin.com/company/solfut-lda---i-have-the-power",
                youtubeLink: "https://www.youtube.com/user/solfut"
            }
        }

        super(props);

        this.tawkToInfo = new TawkToInfo("55e6cced27904b7b2844be4f");
        this.tawkToInfo.Add("pt", "default");
        this.tawkToInfo.Add("en", "19up5d94u");
    }
}

const IhtpSite = new IhtpSiteClass();
export default IhtpSite;
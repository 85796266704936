import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import IhtpSite from "../../../classes/IhtpSite";
import { SiteFactoryClass } from "../../../classes/SiteFactory";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";
import SocialMedia from "../../../components/Helpers/SocialMedia";
import Routes from "../../../helpers/Routes";
import "./Page404.css";

const siteInstance = SiteFactoryClass.GetInstance();

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muted: true,
      playing: true
    };
  }

  onStartVideo(event) {
    if (this.state.muted === true) {
      var that = this;
      setInterval(function () {
        that.setState({
          muted: false
        });
        that.setState({
          playing: true
        });
      }, 4000);
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <br />
        <Container className="mainContentContainer">
          <Row style={{ textAlign: "center", marginBottom: siteInstance.site.hasSocialMedia === false ? "-80px" : "0" }}>
            <Col xs="12" sm="12" md="12" lg="12" style={{ padding: "0px" }}>

              {/* Primeiro texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404 boldText404">
                    <FormattedMessage id="404Page.P1" />
                  </span>
                </Col>
              </Row>

              {/* Segundo texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404">
                    <FormattedMessage id="404Page.P2" />
                  </span>
                </Col>
              </Row>

              <br />

              {/* Terceiro texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404">
                    <FormattedMessage id="404Page.P3" />
                  </span>
                </Col>
              </Row>

              {/* Quarto texto */}
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404">
                    <FormattedMessage id="404Page.P4" />
                    <a className="boldText404" href={`mailto:${siteInstance.site.email}`}>
                      {' ' + siteInstance.site.email + ' '}
                    </a>

                    <br />

                    <FormattedMessage id="404Page.P5" />

                    <br />

                    <FormattedMessage id="404Page.P6" />
                  </span>
                </Col>
              </Row>

              <span className="customSpacing404" />

              {/* Imagem */}
              <Row className="imgParent">
                <Col xs="12" sm="12" md="12" lg="12" xl={9} style={{ padding: "0px" }}>
                  <div className="imgContainer">
                    <img className="img404" src='https://ihavethepower.net/wp-content/uploads/2021/03/ihavethepower-404.jpg' />
                  </div>
                </Col>
              </Row>

              <span className="customSpacing404" />
              <br />

              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <span className="text404">
                    <FormattedMessage id="404Page.P7" />{" "}
                    <a className="boldText404" href={Routes.empresa.empresa}>
                      <FormattedMessage id="404Page.P8" />
                    </a>,

                    {siteInstance.site === IhtpSite &&
                      <>
                        <br />
                        <FormattedMessage id="404Page.P9" />{" "}
                        <a className="boldText404" href={Routes.recursosGratis.frases}>
                          <FormattedMessage id="404Page.P10" />
                        </a>
                      </>
                    }

                    <br />

                    <FormattedMessage id="404Page.P11" />{" "}
                    <a className="boldText404" href={Routes.shop.home}>
                      <FormattedMessage id="404Page.P12" />
                    </a>
                  </span>
                </Col>
              </Row>

              <span className="customSpacing404" />
              <br />
              <SocialMedia />
            </Col>
          </Row>
          <Footer />
        </Container >
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Register);
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

export default function CartTotal() {
    const cart = useSelector((state: any) => state.cart);
    const user = useSelector((state: any) => state.user);
    const currencies = useSelector((state: any) => state.currencies);
    const currency = currencies.selected;

    return (
        <>
            {/* Total */}
            <div>
                {/* Total label */}
                <FormattedMessage id="shop.cart.carrinho.total" />:{" "}

                {/* Total value */}
                <span style={{ fontSize: "20px" }}>
                    <FormattedNumber value={cart.Total} style="currency" currency={currency} minimumFractionDigits={2} maximumFractionDigits={2} />
                </span>
            </div>

            {/* Poupou */}
            {cart.Descontos && cart.Descontos > 0 ? (
                <div style={{ color: "green", fontWeight: "400" }}>

                    {/* Poupou label */}
                    <span style={{ fontSize: "18px" }}>
                        <FormattedMessage id="shop.cart.carrinho.poupar" />{": "}
                    </span>

                    {/* Poupou value */}
                    <span style={{ fontSize: "18px" }}>
                        <FormattedNumber value={cart.Descontos} style="currency" currency={currency} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </span>
                </div>
            ) : null}

            {/* Créditos ou NetPoints*/}
            {["CS", "CT"].includes(user.userType) ? (
                <div style={{ marginBottom: "15px", color: "green" }}>

                    {/* Label Mostrar NetPoints da Encomenda */}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedMessage id="shop.cart.carrinho.netpoints" />{": "}
                    </span>

                    {/* Mostrar valor dos netpoints*/}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedNumber
                            value={cart.Netpoints}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </span>
                </div>
            ) : (
                <div style={{ marginBottom: "15px", color: "green" }}>

                    {/* Label Mostrar Créditos da Encomenda */}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedMessage id="shop.cart.carrinho.creditos" />{": "}
                    </span>

                    {/* Mostrar valor dos Créditos*/}
                    <span style={{ fontSize: "15px" }}>
                        <FormattedNumber
                            value={cart.Creditos}
                            style="currency"
                            currency={currency}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </span>
                </div>
            )}
        </>
    )
}
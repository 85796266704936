import { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import SiteLinksHelper from '../../../../helpers/SiteLinksHelper';

class ShopButton extends Component {
    render() {
        return (
            <a href={SiteLinksHelper.links.storeUrl} className="headerLinks">
                <span>
                    <FormattedMessage id="header.store" />
                </span>
            </a>
        )
    }
}

export default injectIntl(ShopButton);
import Skeleton from '@material-ui/lab/Skeleton';
import { Component } from 'react';
import { ImCart } from 'react-icons/im';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { RemoveDoubleZeroDecimalPlaces } from '../../../../Utils/UsefulFunctions';

class RelatedProductItem extends Component {

    render() {
        const { itemTitle, imageURL, itemId, price, priceToOtherUsers, priceWithoutDiscount,
            currency, onButtonClick, skeleton } = this.props;

        const hrefLink = `${SiteLinksHelper.links.mainSiteUrl}/wp-json/samsyssync/v1/redirectByProductSku/${itemId}`;
        return (
            <div className="relatedProductItemContainer">
                <div className="relatedProductItemSubContainer">
                    <Row style={{ padding: "0px", margin: "0px" }}>
                        <Col xs={12} sm={12} md={12} xl={6} style={{ padding: "0px", margin: "0px" }}>
                            <a href={hrefLink} className="itemImgContainer">
                                {skeleton === true ? (
                                    <Skeleton variant="rect" width={"100%"} height={"100%"} />
                                ) : (
                                    <img className="itemImg" alt="Imagem do Artigo" src={`https://doremon.ihavethepower.net/assets/uploads/${imageURL}`} />
                                )}
                            </a>
                        </Col>

                        <Col xs={12} sm={12} md={12} xl={6} style={{ padding: "0px", margin: "0px" }} className="itemSecondCol">
                            <div className="itemTitle">
                                {skeleton === true ? (
                                    <Skeleton variant="rect" width={"90%"} height={30} />
                                ) : (
                                    <a href={hrefLink} title={itemTitle}>
                                        {itemTitle}
                                    </a>
                                )}

                            </div>

                            <div className="itemPrice">
                                {skeleton === true ? (
                                    <Skeleton variant="rect" width={100} height={30} />
                                ) : (
                                    <div>
                                        {typeof priceToOtherUsers !== 'undefined' && priceToOtherUsers != null ? (
                                            <div className="priceToOtherUsers">
                                                ({RemoveDoubleZeroDecimalPlaces(priceToOtherUsers)}{currency})
                                            </div>
                                        ) : (null)}
                                        {RemoveDoubleZeroDecimalPlaces(price)}{currency}
                                    </div>
                                )}
                            </div>

                            <div className="itemReadMore">
                                {skeleton === true ? (
                                    <Skeleton variant="rect" width={"40%"} height={20} />
                                ) : (
                                    <a href={hrefLink}>
                                        <FormattedMessage id="shop.cart.produtosRelacionados.lerMais" />
                                    </a>
                                )}
                            </div>

                        </Col>

                        <div className="itemButtonContainer">
                            {skeleton === true ? (
                                <Skeleton variant="rect" width={200} height={30} style={{ marginTop: "12px" }} />
                            ) : (
                                <div className="itemButton" onClick={() => onButtonClick(this.props.itemId)}>
                                    <div className="itemButtonIcon">
                                        <ImCart />
                                    </div>
                                    <FormattedMessage id="shop.cart.produtosRelacionados.adicionar" />
                                </div>
                            )}
                        </div>
                    </Row>
                </div>
            </div >
        )
    }
}


export default injectIntl(RelatedProductItem);
import { ImCheckmark } from "react-icons/im";
import { useIntl } from "react-intl";
import RegisterBenefitsLabel from "./RegisterBenefitsLabel";

export default function RegisterBenefitsWonderlives() {
    const intl = useIntl();

    return (
        <>
            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.wonderlives.vantagens.1" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.wonderlives.vantagens.2" })}
                className={"benefitsLabel"} />

            <RegisterBenefitsLabel
                icon={<ImCheckmark color="green" />}
                text={intl.formatMessage({ id: "registar.wonderlives.vantagens.3" })}
                className={"benefitsLabel"} />
        </>
    )
}
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { isVerifiedRecaptcha } from "../../../actions/miniCartActions";
import IHTPRecaptcha from "../../../components/Common/IHTPRecaptcha";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";
import RecoverPasswordDesktop from "../../../components/RecoverPassword/RecoverPassword";
import "./RecoverPassword.css";

export default function RecoverPassword() {
  const dispatch = useDispatch();

  const setRecaptchaVerified = (isVerified: boolean) => {
    dispatch(isVerifiedRecaptcha(isVerified));
  }

  return (
    <div className="App">
      <HeaderDesktop />
      <Container className="mainContentContainer">
        <Row style={{ textAlign: "center" }} >
          <Col xs="12" sm="12" md="12" lg="12">
            <RecoverPasswordDesktop />
            <IHTPRecaptcha setRecaptchaVerified={setRecaptchaVerified} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
const { SiteFactoryClass } = require("../../../../classes/SiteFactory");

const siteInstance = SiteFactoryClass.GetInstance();

exports.HeaderColour = function (user) {
    if (user.persona !== null && user.persona != user.userID) {
        return "#05ef00";
    }

    if (user.ConsultantType == 0) {
        return "#e2e220"
    }

    if (user.ConsultantType == 2) {
        return "#ff0000"
    }

    return siteInstance.site.headerColour;
};

exports.CartItemColour = function (user) {
    if (user.ConsultantType == 2) {
        return "#ff0000"
    }

    return "#fff";
}


exports.CartItemBgColour = function (user) {
    if (user.ConsultantType == 2) {
        return "#fff"
    }

    return "#ff0000";
}